<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form" @submit.prevent="ValidateForm">
          <v-row>
            <v-col cols="8" md="8">
              <v-text-field
                  dense
                  outlined
                  :label="$t('BusinessType.form.businessInput')"
                  v-model="businessType.name"
                  :rules="nameRules"
                  required
              >
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-btn class="btn-save-change" type="submit">
                {{ $t("BusinessType.form.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>

export default {
  props: {
    businessType: {}
  },
  data() {
    return {
      nameRules: [
        v => !!v || 'field is required...'
      ],
      data: {
        name: "",
      }
    }
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.UpdateBusinessType();
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },

    UpdateBusinessType() {
      this.$axios.put(`admin/type-business/${this.businessType.id}`, this.businessType).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          setTimeout(() => {
            this.resetForm()
          }, 300)
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
  created() {
    console.log(this.businessType)
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 0 10px 0 10px;
  margin-right: auto;
  margin-left: auto;

}

.from-content {
  width: 100%;
  height: 100vh;
  padding: 40px 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 800px;
    height: 500px;
  }

}
</style>
