<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form" @submit.prevent="ValidateForm">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  dense
                  outlined
                  :label="$t('BusinessType.form.businessInput')"
                  v-model="data.name"
                  :hint="`${server_errors.name}`"
                  persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
                {{ $t("BusinessType.form.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      businessType: [],
      btnLoading: false,
      nameRules: [
        v => !!v || 'field is required...'
      ],
      data: {
        name: "",
      },
      server_errors: {
        name: "",
      }
    }
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    saveChange() {
      this.btnLoading = true;
      this.$axios.post(`admin/type-business`, this.data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          setTimeout(() => {
            this.resetForm()
          }, 300)
          this.$router.push({name: "businessType.index"})
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },

}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 0 10px 0 10px;
  margin-right: auto;
  margin-left: auto;

}

.from-content {
  width: 100%;
  height: 100%;
  padding: 40px 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 800px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

}
</style>
